import { Component } from '@angular/core';

@Component({
  selector: 'app-mision',
  templateUrl: './mision.component.html',
  styleUrls: ['./mision.component.css']
})
export class MisionComponent {

  misionTexto: string = `Somos una marca Boliviana que produce y comercializa embutidos y productos cárnicos, realizados con responsabilidad, calidad, innovación y un gran sabor que satisfacen las necesidades de nuestros clientes, cuidando su salud y bienestar, bajo normas y estándares de seguridad alimentaria. Contamos con un equipo humano competente y capaz con sólidos principios éticos comprometidos con la empresa y orientado al servicio de sus consumidores.`;
}