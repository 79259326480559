import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-why-bg',
  templateUrl: './why-bg.component.html',
  styleUrls: ['./why-bg.component.css']
})
export class WhyBgComponent implements OnInit {
  @ViewChild('carouselScroll', { static: true }) carouselScroll!: ElementRef;

  features = [
    {
      icon: 'fas fa-hotdog',
      title: 'Calidad Premium',
      description: 'Usamos carne seleccionada de la mejor calidad para que cada bocado sea irresistible.'
    },
    {
      icon: 'fas fa-thumbs-up',
      title: 'Sabor Inigualable',
      description: 'Un sabor único y delicioso que te hará volver por más, ideal para cualquier ocasión.'
    },
    {
      icon: 'fas fa-check',
      title: 'Confianza Garantizada',
      description: 'Con más de una década en el mercado, nuestros productos son un sinónimo de confianza.'
    },
    {
      icon: 'fas fa-money-bill-alt',
      title: 'Mejor Valor',
      description: 'Alta calidad a precios accesibles, dándote más por menos en cada compra.'
    },
    {
      icon: 'fa fa-industry',
      title: 'Apoyo Nacional',
      description: '100% productos bolivianos, apoyando lo nuestro y promoviendo lo local.'
    }
  ];

  constructor() { }

  ngOnInit(): void { }

  scrollLeft() {
    this.carouselScroll.nativeElement.scrollBy({ left: -220, behavior: 'smooth' });
  }

  scrollRight() {
    this.carouselScroll.nativeElement.scrollBy({ left: 220, behavior: 'smooth' });
  }
}
