import { Component } from '@angular/core';

@Component({
  selector: 'app-accesibilidad-medidas-seguridad',
  templateUrl: './accesibilidad-medidas-seguridad.component.html',
  styleUrls: ['./accesibilidad-medidas-seguridad.component.css']
})
export class AccesibilidadMedidasSeguridadComponent {

}
