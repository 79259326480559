import { Component } from '@angular/core';

@Component({
  selector: 'app-contacto-como-llegar',
  templateUrl: './contacto-como-llegar.component.html',
  styleUrls: ['./contacto-como-llegar.component.css']
})
export class ContactoComoLlegarComponent {

}
