<div class="row altura-car1 align-items-center">
  <div class="col-12 col-lg-6 col-md-6 py-3 text-center">
    <p class="h2">Los mejores <br> productos</p>
  </div>
  <div id="carouselIndicators" class="carousel slide car1 py-3 col-12 col-lg-6 col-md-6" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0" class="active" aria-current="true"
        aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/angular-html-517d8.appspot.com/o/cmb-3.webp?alt=media&token=5070f84c-9f92-428d-a63b-c5b1e2c90bdb"
          class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/angular-html-517d8.appspot.com/o/cmb-2.webp?alt=media&token=bc5d4cae-4020-441a-8984-93551d6b50e2"
          class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/angular-html-517d8.appspot.com/o/cmb-1.webp?alt=media&token=6f9b67fa-12f2-4117-aaa4-fe8b2eaf051a"
          class="d-block w-100" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>