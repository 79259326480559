// fotos-historia.component.ts
import { Component, OnInit } from '@angular/core';
import { InfoProductosService } from 'src/app/services/info-productos.service';
@Component({
  selector: 'app-fotos-historia',
  templateUrl: './fotos-historia.component.html',
  styleUrls: ['./fotos-historia.component.css']
})
export class FotosHistoriaComponent implements OnInit {
  fotosHistoria: any[] = [];

  constructor(private infoProductosService: InfoProductosService) { }

  ngOnInit(): void {
    this.infoProductosService.getCaruselHistoria().subscribe((fotos: any[]) => {
      this.fotosHistoria = fotos.filter(foto => foto.vigente === 'si');
    });
  }
}
