<div class="ani">

  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-12 text-center">
        <img src="https://imagenesbg.blob.core.windows.net/paginaweb/C1.jpg" alt="Imagen de la celebración" class="rounded-3 my-3 h-50 mx-auto d-block img-fluid">
      </div>
      <div class="col-lg-8 my-2 mx-auto shadow">
        <h2 class="pt-4 fs-3">
          Celebremos 20 Años de Sabor y Solidaridad con Bon Gusto
        </h2>
        <p>Fecha y Hora: 21 de octubre de 2023 a las 18:30</p>
        <p>Lugar: Salón Sancho Panza, ubicado en la avenida Illampu 408, esquina Sagárnaga, piso 9. <a class="enlace-google-maps" href="https://maps.app.goo.gl/Y9aQZGuz5xMymJHJ8" target="_blank">Ver en Google Maps</a></p>
      </div>
    </div>
  </div>
  
  
  
  
  
  
  
  
  

  <!-- Contenido repetitivo en contenedores optimizados -->
  <div class="container min-vh-50">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-8 col-12 shadow-lg shadow-md-down m-sm-1 p-sm-1 m-lg-5 p-lg-5">
        <div class="nosotros-info">
          <h2 class="pt-4 fs-3">
            ¡Estamos emocionados por nuestros 20 años de sabor excepcional!
          </h2>
          <p>Bon Gusto, una empresa familiar que ha crecido y prosperado a lo largo de dos décadas, te invita a una fiesta de aniversario que marcará esta ocasión especial. Desde nuestros humildes comienzos hasta convertirnos en un referente en alimentos de calidad, hemos mantenido el compromiso con el sabor y la autenticidad.</p>
        </div>
      </div>
    </div>
  </div>
  

  <div class="container min-vh-50 d-flex justify-content-center align-items-center">
    <div class="col-md-8 col-lg-8 col-12 shadow-lg shadow-md-down m-sm-1 p-sm-1 m-lg-5 p-lg-5">
      <div class="nosotros-info">
        <h2 class="pt-4 fs-3">
          20 años de Éxito
        </h2>
        <p>Nuestra historia se remonta a 2003, cuando el Sr. Alejandro Quisbert y la Sra. Esperanza Burgoa decidieron emprender un negocio familiar en el rubro de embutidos. Con su conocimiento y experiencia en la producción de chorizo parrillero, tocino ahumado y jamón, los fundadores dieron vida a una empresa que se convertiría en un referente en la industria alimentaria boliviana. Desde entonces, hemos experimentado un crecimiento constante y diversificado, manteniendo siempre la calidad y la pasión por nuestros productos.</p>
      </div>
    </div>
  </div>
  
  <div class="container min-vh-50 d-flex justify-content-center align-items-center">
    <div class="col-md-8 col-lg-8 col-12 shadow-lg shadow-md-down m-sm-1 p-sm-1 m-lg-5 p-lg-5">
      <div class="nosotros-info">
        <h2 class="pt-4 fs-3">
          ¡Juntos Hacemos la Diferencia!
        </h2>
        <p>Mantente atento a nuestras redes sociales para más detalles sobre la fiesta y cómo ser parte de esta causa especial. ¡Celebraremos dos décadas de sabor y solidaridad en grande!</p>

      </div>
    </div>
  </div>
  

  <div class="container min-vh-50 d-flex justify-content-center align-items-center">
    <div class="col-md-6 col-lg-6 col-12 m-sm-1 p-sm-1 m-lg-2 p-lg-5">
      <div class="nosotros-info">
        <h3 class="pt-4 fs-4">
          Mayor información sobre el convenio:
        </h3>

      </div>
    </div>
  </div>

  <hr>

  <div class="container min-vh-50 d-flex justify-content-center align-items-center">
    <div class="col-md-12 col-lg-12 col-12 shadow-sm m-sm-1 p-sm-1 m-lg-5 p-lg-5 celebracion">
      <!-- Agregamos la imagen -->
      <img src="https://imagenesbg.blob.core.windows.net/paginaweb/C2.png" alt="Imagen de la celebración" class="img-fluid rounded-circle d-block mx-auto" style="max-width: 50%;">
      <!-- Fin de la imagen -->
      <div class="nosotros-info">
        <h2 class="pt-4 fs-4">
          COMUNICADO: ALIANZA BON GUSTO – BOMBEROS FORESTALES QUEBRACHO
        </h2>
        <p>En el marco de la celebración de nuestros 20 años en el mercado boliviano y como parte de nuestra Responsabilidad Social Empresarial, nos complace anunciar una colaboración especial con una institución clave en la protección del medio ambiente: los Bomberos Forestales Quebracho de la ciudad de Santa Cruz.</p>
        <p>Desde 2019, han trabajado en la liquidación de incendios forestales que han golpeado las áreas verdes más importantes de nuestro país y a la fecha se encuentra trabajando en campañas para poder equipar a sus voluntarios, así como terminar de equipar el PRIMER CARRO BOMBA FORESTAL que tiene nuestro país.</p>
        <p>En el marco de nuestra celebración, hemos extendido una cordial invitación a autoridades, figuras públicas y destacadas personalidades de nuestra sociedad para que, además de celebrar con nosotros, se sumen a esta causa. Por cada asistencia de nuestros INVITADOS ESPECIALES, Bon Gusto realizará una donación de 500 bolivianos.</p>
        <p>La totalidad de las donaciones será transparentada el día del evento, cuando nuestros INVITADOS ESPECIALES firmen un cuadro que forma parte de una obra del artista plástico EUSEBIO CHOQUE. Al finalizar el evento, se calculará el total de firmas, multiplicando cada una por 500 bolivianos. Esta suma final será donada por Bon Gusto a nombre de los asistentes a la Fundación de BOMBEROS FORESTALES QUEBRACHO.</p>
        <p>E invitamos a unirte a nosotros en este evento especial, que se llevará a cabo el sábado 21 de octubre a las 18:30 en el Salón Sancho Panza, ubicado en la avenida Illampu 408, esquina Sagárnaga, piso 9.</p>
        <p>Agradecemos tu apoyo y tu compromiso con esta causa que beneficia tanto a nuestra sociedad como a nuestro entorno natural.</p>
      </div>
    </div>
  </div>
  
  
  

</div>
