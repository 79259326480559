<div class="container min-vh-50 d-flex justify-content-center align-items-center">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-lg-6">
      <div class="nosotros-info text-center">
        <h2>Nuestros Valores</h2>
        <div class="row row-cols-2 row-cols-md-3 g-4 my-3">
          <div class="col" *ngFor="let valor of valores">
            <mat-card class="value-card mx-auto bg-transparent">
              <mat-card-header>
                <mat-icon aria-hidden="false" aria-label="Icono valor">{{ valor.icon }}</mat-icon>
              </mat-card-header>
              <mat-card-content>
                <p>{{ valor.nombre }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>