import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compranos',
  templateUrl: './compranos.component.html',
  styleUrls: ['./compranos.component.css']
})
export class CompranosComponent implements OnInit {
  baseUrl: string = 'https://firebasestorage.googleapis.com/v0/b/angular-html-517d8.appspot.com/o/';

  benefits: { image: string, title: string }[] = [
    {
      image: this.baseUrl + 'footer-benefit-pick-store.png?alt=media&token=18177c62-9f8d-40e2-a89d-e7ed9c792617',
      title: 'Entrega en su local'
    },
    {
      image: this.baseUrl + 'footer-benefit-delivery.png?alt=media&token=ea0630a0-81ee-44a4-9caf-7667d0ea747c',
      title: 'Delivery programado'
    },
    {
      image: this.baseUrl + 'footer-benefit-delivery-express.png?alt=media&token=c513e150-a4cf-498b-a68a-5cd41ee66655',
      title: 'Delivery Express'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }
}
