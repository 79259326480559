<div class="app-fotos-historia">
  <div id="carouselHistoria" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button *ngFor="let foto of fotosHistoria; let i = index" type="button" [attr.data-bs-target]="'#carouselHistoria'" [attr.data-bs-slide-to]="i" [class.active]="i == 0" aria-current="true" [attr.aria-label]="'Slide ' + (i+1)"></button>
    </div>
    <div class="carousel-inner">
      <div *ngFor="let foto of fotosHistoria; let i = index" class="carousel-item" [class.active]="i == 0">
        <img [src]="foto.urlimagen" class="d-block w-100" [alt]="foto.descripcion">
        <div class="carousel-caption d-none d-md-block">
          <p class="description">{{ foto.descripcion }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
