// info-productos.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoProductosService {
  private urlPortada = 'https://angular-html-517d8-default-rtdb.firebaseio.com/portada.json';
  private urlCarousel = 'https://angular-html-517d8-default-rtdb.firebaseio.com/carusel.json';
  private urlTarjetasProductos = 'https://angular-html-517d8-default-rtdb.firebaseio.com/tarjetas.json';
  private urlCaruselHistoria = 'https://angular-html-517d8-default-rtdb.firebaseio.com/fotoshistoria.json';

  


  constructor(private http: HttpClient) { }

  getProductosPortada(): Observable<any[]> {
    return this.http.get<any[]>(this.urlPortada);
  }

  getProductosInLine(): Observable<any[]> {
    return this.http.get<any[]>(this.urlCarousel);
  }

  getTarjetasProductos(): Observable<any[]> {
    return this.http.get<any[]>(this.urlTarjetasProductos);
  }

  getCaruselHistoria(): Observable<any[]> {
    return this.http.get<any[]>(this.urlCaruselHistoria);
  }
}
